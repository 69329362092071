import React from 'react';
import {FaBars, FaTimes, FaGithub, FaLinkedin, FaLink} from 'react-icons/fa'
import { HiOutlineMail } from 'react-icons/hi'
import {FaDonate} from 'react-icons/fa'

const Contact = () => {
  return (
    <div name='contact' className='w-full h-screen bg-[#0a192f] text-gray-300'>
      <div className='flex flex-col justify-center items-center w-full h-full'>
        <div className='max-w-[1000px] w-full grid grid-cols-2 gap-8'>
          <div className='sm:text-right pb-8 pl-4'>
            <p className='text-4xl font-bold inline border-b-4 border-blue-500'>
              Contact
            </p>
          </div>
          <div></div>
          </div>
          <div className='max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4'>
            <div className='sm:text-right font-bold'>
              <p>Feel free to check out my other pages</p>
              <p>and get in touch via email:</p>
            </div>
            <div className="social-links">
            <a href="https://github.com/infinitepower18" target="_blank" rel="noreferrer"><FaGithub size={30}/></a>
            <a href="https://linkedin.com/in/ahnafm" target="_blank" rel="noreferrer"><FaLinkedin size={30}/></a>
            <a href="mailto:mail@ahnafmahmud.com"><HiOutlineMail size={30}/></a>
            <a href="https://ko-fi.com/F1F1K06VY" target="_blank" rel="noreferrer"><FaDonate size={30}/></a>
            </div>
          </div>
      </div>
    </div>
  );
};

export default Contact;