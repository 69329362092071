import React from 'react'
import {Link} from 'react-scroll';

const Home = () => {
  return (
    <div name='home' className='w-full h-screen bg-[#0a192f]'>
        {/* Container */}
        <div className='max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full'>
            <p className='text-blue-400'>Hi, I'm</p>
            <hi className='text-4xl sm:text-7xl font-bold text-[#ccd6f6]'>Ahnaf Mahmud</hi>
            <h2 className='text-4xl sm:text-7xl font-bold text-[#8892b0]'>A software developer.</h2>
            <p className='text-[#ffffff] py-4 max-w-[700px]'>I'm a computer science graduate with a passion for creating software. Some of my work includes SubManager, a subscription manager app for Apple platforms as well as open source projects such as WSA Sideloader, the popular APK installer for Windows Subsystem for Android. I also contribute to other projects from time to time.</p>
            <div>
                <button className='text-white border-2 px-6 py-3 my-2 flex items-center hover:bg-blue-600 hover:border-blue-600'><Link to="projects" smooth={true} offset={50} duration={500}>
                        View projects
                    </Link>
                </button>
            </div>
        </div>
    </div>
  )
}

export default Home
